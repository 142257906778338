@font-face {
  font-family: "Quicksand";
  src: url("./../public/Quicksand-VariableFont_wght.ttf");
}
/* Importing an external CSS file */
@import url('./components/elements/PageScrolling.css');
@import url('./components/elements/ArticleBlocks.css');
/***********************************************
 Content part 
 ***********************************************/
body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', Arial, sans-serif;
  background-color: #1a1a1a;
  color: #F0F0F1;
  /* Green color for the text */
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: scroll-y;
  /* Hide overflow to prevent scrollbars */
  flex-direction: column;
  /* Set the main layout direction */
  min-height: 100vh;
  /* Ensure the page takes up at least the viewport height */
  position: relative;
  /* Set position to relative for stacking context */
}

.master-frame {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #01010B;
  width: 100%; /* Full width by default */
  min-height: 100vh; /* Minimum height is full viewport height */
  padding: 0; /* No padding by default */
  flex-grow: 1; /* Allows the container to grow if the content is larger than the viewport */
}

/* Media query for larger screens (like desktops) */
@media (min-width: 768px) {
  .master-frame {
      width: 90%;
      height: auto; /* Change this to 'auto' to allow for dynamic height */
      margin: auto; /* Center the content */
      padding: 0; /* Adjust padding as needed */
  }
}

/***********************************************
 Header part 
 ***********************************************/
header {
  background: linear-gradient(120deg, rgba(0, 0, 0, 1) 0%, rgba(23, 23, 27, 1) 30%, rgb(84, 46, 130) 50%, rgba(51, 50, 55, 1) 65%);
  width: 100%;
}

.header-content { /* New wrapper for flex items */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-foot-line {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, rgba(43, 50, 66, 1) 0%, rgba(138, 80, 102, 1) 22%, rgba(130, 50, 143, 1) 48%, rgba(47, 43, 53, 1) 65%);
}

.header-logo,
.header-title {
  display: flex;
  align-items: center;
}

.header-logo img {
  width: 100px;
  /* Adjust as needed */
}

.header-title h1 {
  margin-left: 10px;
  font-size: min(1.5em, 8vw);
  font-weight: 1000;
  width: max-content;
  text-align: center;
  background-color: rgb(255, 178, 62);
  background-image: linear-gradient(268.67deg, rgb(219, 189, 255) 3.43%, rgb(203, 160, 255) 15.69%,rgb(170, 105, 249) 55.54%, rgb(139, 44, 255) 99%);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}

.header-menu {
  display: flex;
  color: #F0F0F1;
  margin-right: 10px;
}

.header-menu-item {
  margin-left: 20px;
  /* Space between menu items */
  cursor: pointer;
  font-weight: 800;
}

/***********************************************
 Footer part 
 ***********************************************/

footer {
  background-color: #03030D;
  width: 100%;
  text-align: center;
}

.footer-head-line {
  width: 100%;
  height: 2px;
  background-color: #110a20;
}



main {
  display: flex;
  justify-content: center;
  align-items: center;  
  width: 100%;
}

.icon {
  display: inline-block;
  margin-right: 5px;
}

a {
  color: #F0F0F1;
  /* Dark blue color for the links */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}