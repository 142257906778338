 .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%; /* Form takes the full width of its container */
  }
  
  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .form-group label {
    width: 30%; /* Set a specific width for labels */
    text-align: left;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group textarea {
    width: 60%; /* Set a specific width for inputs */
    padding: 8px;
    background-color: #D542A3; /* Light purple background */
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #4a2fba; /* Darker purple for contrast */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #37208d;
  }
  