@import url('./elements/ArticleBlocks.css');

.content-wrapper-about {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.profile-picture {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    border: 1px solid #ccc;
}